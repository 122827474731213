import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import cookie from 'react-cookies'

import { connect } from 'react-redux'
import * as actions from '../Actions/index'

import imgDeleteCart from '../assets/images/icon_trash.png'

class CartPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // JSONcart: JSON.parse(localStorage.getItem("cart")),
            JSONcart: cookie.load('cart')
        }
    }

    handleRemoveProduct = (id) => {
        let { JSONcart } = this.state;

        console.log(id);

        for (let index in JSONcart) {
            if (JSONcart[index].id === id) {
                JSONcart.splice(index, 1);

                // localStorage.setItem("cart", JSON.stringify(JSONcart));
                cookie.save('cart', JSONcart, {path: '/' })
                this.props.getSizeOfCart();
                this.setState({ JSONcart });
                break;
            }
        }
    }

    handleOnChange = (id, event) => {
        let { JSONcart } = this.state;

        for (let index in JSONcart) {
            if (JSONcart[index].id === id) {
                JSONcart[index].numbers = parseInt(event.target.value, 10);
                // localStorage.setItem("cart", JSON.stringify(JSONcart));
                cookie.save('cart', JSONcart, {path: '/' });
                this.props.getSizeOfCart();
                this.setState({ JSONcart })
                break;
            }
        }
    }

    render() {
        let { JSONcart } = this.state;

        return (
            <div>
                <div className="breadcrumb-box fl" style={{ marginTop: 10, marginBottom: 10 }}>
                    <ul className="breadcrumb">
                        <li><Link to="/">TRANG CHỦ</Link></li>
                        <li><Link to="/gio-hang">GIỎ HÀNG</Link></li>
                    </ul>
                </div>

                <div className="clr"></div>

                <div className="content-box">
                    <div className="row">
                        <div className="col-md-7 fleft" style={{width: '55%'}}>
                            <div className="customer-area contact-form">
                                <form className="form_cart_submit">
                                    <h2 className="customer-title">
                                        Thông tin người nhận hàng
                                    </h2>
                                    <div className="customer-form">
                                        <div className="form-group">
                                            <label>Họ và tên</label>
                                            <input
                                                type="text"
                                                name="hoten"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Địa chỉ</label>
                                            <input
                                                type="text"
                                                name="diachi"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                Điện thoại <span className="zing-required">*</span>
                                            </label>
                                            <input
                                                type="tel"
                                                name="sodienthoai"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Ghi chú</label>
                                            <textarea
                                                name="noidung"
                                                className="form-control"
                                                defaultValue={""}
                                            />
                                        </div>
                                        <input
                                            type="submit"
                                            className="cart-submit"
                                            name="submit"
                                            value="Đặt hàng"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-5 fright" style={{width: '45%'}}>
                            <div className="cart-box">
                                <h2 className="gio-hang-title">Thông tin đơn hàng</h2>
                                {
                                    JSONcart.length
                                        ?
                                        JSONcart.map((item, index) => {
                                            return (
                                                <div key={index} className="cart-item">
                                                    <div className="fleft" style={{ width: 280 }}>
                                                        <Link to={item.to}>
                                                            <h3 className="cart-title fleft">
                                                                {item.title}
                                                            </h3>
                                                        </Link>
                                                        <p className="fleft" style={{ fontSize: 15, position: "absolute", marginTop: 40, marginLeft: 30 }}>Giá: <span style={{ color: '#ed3237' }}>{item.price}</span></p>
                                                        <p style={{ fontSize: 15, position: "absolute", marginTop: 70, marginLeft: 30 }}>
                                                            Số lượng:
                                                            <input
                                                                className="cart-qty"
                                                                type="number"
                                                                min={1}
                                                                max={999}
                                                                value={item.numbers}
                                                                onChange={this.handleOnChange.bind(this, item.id)}
                                                            />
                                                        </p>
                                                    </div>


                                                    <img
                                                        className="cart-img"
                                                        src={item.src}
                                                        alt={item.title}
                                                    />

                                                    <div className="btn-delete-cart" onClick={this.handleRemoveProduct.bind(this, item.id)}>
                                                        <img
                                                            src={imgDeleteCart}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="cart-empty">
                                            <p>Bạn chưa có sản phẩm nào trong giỏ hàng</p>
                                        </div>
                                }

                            </div>
                        </div>
                        <div className="clr" />
                    </div>
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSizeOfCart: () => dispatch(actions.getSizeOfCart())
    }
}

export default connect(null, mapDispatchToProps)(CartPage);