import React from 'react';

import { Link } from 'react-router-dom'

const RelatedProducts = (props) => {
    let { relatedProducts } = props;

    return (
        <div className="home-box content-box">
            <h2 className="box-title lien-quan-title" style={{ textAlign: 'left', marginLeft: 15 }}>Sản phẩm liên quan</h2>
            <div className="product-list">
                {
                    relatedProducts.map((product, index) => {
                        return (
                            <div key={index} className="product-item" title= {product.title}>
                                <div className="product-img">
                                    <Link to={product.to}>
                                        <span className="buy-now-label">Mua ngay</span>
                                        <img src={product.src} alt={product.title} />
                                    </Link>
                                </div>

                                <div className="product-info">
                                    <h3 className="product-title">
                                        <Link to={product.to}>{product.title}</Link>
                                    </h3>

                                    <div className="product-price">{product.price}</div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="clr"></div>
            </div>
        </div>

    );
};

export default RelatedProducts;