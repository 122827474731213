import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const mainMenu = [
    { content: 'TRANG CHỦ', to: '/' },
    { content: 'MÁY SCAN', to: '/may-scan' },
    { content: 'MÁY IN', to: '/may-in' },
    { content: 'MỰC IN', to: '/muc-in' },
    { content: 'ĐẦU IN', to: '/dau-in' },
    { content: 'GIẤY IN', to: '/giay-in' },
    { content: 'DỊCH VỤ IN ẤN', to: '/dich-vu-in-an' },
    { content: 'LIÊN HỆ', to: '/lien-he' }
]

class Header extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            posMenuActive: this.getPosActiveMenu(),
        }
    }
    
    getPosActiveMenu = () => {
        let pathname = window.location.pathname;
        
        switch (pathname.split('/')[1]) {
            case '':
                return 0;
            case 'may-scan':
                return 1;
            case 'may-in':
                return 2;
            case 'muc-in':
                return 3;
            case 'dau-in':
                return 4;
            case 'giay-in':
                return 5;
            case 'dich-vu-in-an':
                return 6;
            case 'lien-he':
                return 7;
            case 'gio-hang':
                return 8;
            default:
                return -1;
        }
    }


    componentWillUpdate(prevProps) {
        const pathnameChanged = this.props.pathname !== prevProps.pathname;         

        if (pathnameChanged) {
            this.setState({
                posMenuActive: this.getPosActiveMenu()
            })
        }
    }

    render() {
        let {contact, sizeCart} = this.props;
        let {posMenuActive} = this.state;        
                
        return (
            <div id="header" style={{ height: 160 }}>
                <div className="container">
                    {/* Logo */}
                    <div className="fleft header-left">
                        <div className="logo">
                            <Link to="/">
                                <img style={{ height: 115 }} src={require('../assets/images/logo.png')} alt="" ></img>
                            </Link>
                        </div>
                    </div>

                    {/* menu item + contact */}
                    <div className="fright header-right">
                        <div className="header-right-top">
                            <button className="view-menu"><i className="fa fa-list" /></button>
                            <button className="view-search"><span className="fa fa-search" /></button>
                            <div className="fleft main-menu">
                                <ul style={{ fontWeight: "bold" }}>
                                    {
                                        mainMenu.map((item, index) => {
                                            return (
                                                <li key={index} className={`menu-item ${index === posMenuActive ? 'active' : ''}`}>
                                                    <Link to={item.to}>
                                                        {item.content}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }

                                    <li className={`menu-item ${8 === posMenuActive ? 'active' : ''}`}>
                                        <Link to="/gio-hang">
                                            <i className="fa fa-shopping-cart" /> GIỎ HÀNG ({sizeCart})</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <h1 className="company-name" style={{ color: "green" }}><strong>CÔNG TY TNHH PAXAR VIỆT NAM</strong></h1>

                        <div className="header-right-bottom">
                            <p>Điện thoại: <span>{contact.tel}</span></p>
                            <p>Hotline: <span>{contact.hotline}</span></p>
                            <p>Fax: <span>{contact.fax}</span></p>
                            <p>Email: <span>{contact.email}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sizeCart: state.sizeCart
    }
}

export default connect(mapStateToProps, null)(Header);