import * as types from '../Constants/actionTypes'

// import home from '../Data/home'
// import scanners from '../Data/scanners'
// import axios from 'axios'

// const handlePathname = async (pathname) => {
//     pathname = '/' + pathname.split('/')[1];
//     let url = "";

//     switch (pathname) {
//         case '/':
//             url = `https://paxavietnam-server.vercel.app/api/pages/home?key=${process.env.REACT_APP_API_KEY}`;
//             break;
//         case '/may-scan':
//             url = `https://paxavietnam-server.vercel.app/api/pages/scanners?key=${process.env.REACT_APP_API_KEY}`;
//             break;
//         default:
//             break;
//     }


//     let promise = new Promise((resolve, reject) => {
//         axios({
//             method: 'get',
//             url
//         })
//             .then(res => resolve(res.data))
//             .catch(err => reject(err))
//     })

//     let pages = await promise;
//     console.log(pages);

//     // switch (pathname) {
//     //     case '/':
//     //         return home;
//     //     case '/may-scan':
//     //        return scanners;
//     //     default:
//     //         return null;
//     // }

//     return pages;
// }

// let initializeState = handlePathname(window.location.pathname);

let initializeState = null;

let reducer = (state = initializeState, action) => {
    switch (action.type) {
        case types.GET_DATA_OF_PAGE: {
            // if (action.pathname)
            //     return handlePathname(action.pathname);
            // else
            //     return state;
            
            if (action.dataOfPage) 
                return action.dataOfPage;

            return state;
        }

        default:
            return state;
    }
}

export default reducer;