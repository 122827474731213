import React from 'react';

import carousel0 from '../assets/images/carousel-0.jpg'
import carousel1 from '../assets/images/carousel-1.jpg'
import carousel2 from '../assets/images/carousel-2.jpg'
import carousel3 from '../assets/images/carousel-3.jpg'

const Carousel = () => {
    const styles = {
        carousel: {
            width: '900px',
            height: '410px',
            float: 'right'
        },

        imageCarousel: {
            width: '890px',
            height: '395px'
        }
    }

    return (
        <div>
            <div style={styles.carousel}>
                <div id="carousel" className="carousel slide" data-ride="carousel" style={{ margin: "5px" }}>
                    <ol className="carousel-indicators">
                        <li data-target="#carousel" data-slide-to={0} className="active" />
                        <li data-target="#carousel" data-slide-to={1} />
                        <li data-target="#carousel" data-slide-to={2} />
                        <li data-target="#carousel" data-slide-to={3} />
                    </ol>

                    <div className="carousel-inner">
                        {/* {itemOfCarousel} */}
                        <div className={'item active'}>
                            <img src={carousel0} style={styles.imageCarousel} alt="carousel" />
                        </div>
                        <div className={'item'}>
                            <img src={carousel1} style={styles.imageCarousel} alt="carousel" />
                        </div>
                        <div className={'item'}>
                            <img src={carousel2} style={styles.imageCarousel} alt="carousel" />
                        </div>
                        <div className={'item'}>
                            <img src={carousel3} style={styles.imageCarousel} alt="carousel" />
                        </div>
                    </div>

                    <a className="left carousel-control" href="#carousel" data-slide="prev">
                        <span className="glyphicon glyphicon-chevron-left" />
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="right carousel-control" href="#carousel" data-slide="next">
                        <span className="glyphicon glyphicon-chevron-right" />
                        <span className="sr-only">Next</span>
                    </a>

                </div>
            </div>

            <div className="clr"></div>
        </div>
    );
};

export default Carousel;