import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import cookie from 'react-cookies'

import { connect } from 'react-redux'
import * as actions from '../Actions/index'

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAdded: false
        }
    }

    addToCart = (JSONcart, product) => {
        let isExisted = false;

        for (let index in JSONcart) {
            if (JSONcart[index].id === product.id) {
                JSONcart[index].numbers = JSONcart[index].numbers + 1;
                isExisted = true;
                break;
            }
        }

        if (!isExisted) {
            JSONcart = [...JSONcart, {
                id: product.id,
                title: product.title,
                price: product.price,
                src: product.src,
                to: product.to,
                numbers: 1
            }];
        }

        return JSONcart;
    }

    handleAddToCart = () => {
        let { isAdded } = this.state;
        let { product } = this.props;

        // let JSONcart = JSON.parse(localStorage.getItem("cart"));
        let JSONcart = cookie.load('cart');

        if (!JSONcart) {
            JSONcart = [];
            // localStorage.setItem("cart", JSON.stringify(JSONcart));
            cookie.save('cart', JSONcart, { path: '/' });
        }

        if (isAdded) {
            for (let index in JSONcart) {
                if (JSONcart[index].id === product.id) {
                    JSONcart[index].numbers = JSONcart[index].numbers - 1;

                    if (JSONcart[index].numbers === 0) {
                        JSONcart.splice(index, 1);
                    }
                    break;
                }
            }

            // localStorage.setItem("cart", JSON.stringify(JSONcart));
            cookie.save('cart', JSONcart, { path: '/' });
        } else {
            JSONcart = this.addToCart(JSONcart, product);
            // localStorage.setItem("cart", JSON.stringify(JSONcart));
            cookie.save('cart', JSONcart, { path: '/' });
        }

        this.props.getSizeOfCart();

        this.setState({
            isAdded: !isAdded
        });
    }

    handleBuyNow = () => {
        let { isAdded } = this.state;
        let { product } = this.props;

        if (!isAdded) {
            // let JSONcart = JSON.parse(localStorage.getItem("cart"));
            let JSONcart = cookie.load('cart');
            JSONcart = this.addToCart(JSONcart, product);
            // localStorage.setItem("cart", JSON.stringify(JSONcart));
            cookie.save('cart', JSONcart, { path: '/' });
        }

        this.props.getSizeOfCart();

        this.setState({
            isAdded: !isAdded
        });
    }

    render() {
        const { product, breadcrumbLink } = this.props;

        return (
            <div className="product">
                <div className="breadcrumb-box fl" style={{ marginTop: 10, marginBottom: 10 }}>
                    <ul className="breadcrumb">
                        {
                            product.breadcrumb.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={breadcrumbLink[index]}>
                                            {item}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="clr"></div>
                <div className="content-box" style={{ paddingTop: 10 }}>
                    <div className="product-left" >
                        <div className="img-main" style={{ margin: "auto" }}>
                            <img
                                className="img-zoom"
                                style={{ maxHeight: 370, maxWidth: 370 }}
                                src={product.src}
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="product-right">
                        <h1 className="single-product-title">{product.title}</h1>

                        <div className="product-right-info">
                            <p className="single-product-price">{product.price}</p>
                            <div className="clr" />
                            <div className="product-right-content">
                                <p className="product-right-title">Thông tin sản phẩm</p>
                                <p style={{ marginBottom: 7 }}>- Hãng sản xuất: {product.manufacturer}</p>
                                <p>- Xuất xứ: Chính hãng</p>
                                <p className="product-right-title">Mô tả sản phẩm</p>
                                <div className="mo-ta-san-pham">
                                    <ul>
                                        {
                                            product.description.split('\n').map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span>{item}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="product-btn">
                                <Link to="/gio-hang" className="btn-buy" onClick={this.handleBuyNow}>Mua ngay</Link>
                                <span className="btn-add-cart" onClick={this.handleAddToCart}><span>{this.state.isAdded ? "Đã thêm vào giỏ" : "Thêm vào giỏ hàng"}</span></span>
                            </div>

                        </div>
                    </div>
                    <div className="clr" />

                    <div className="single-product-box">
                        <div className="zing-tab">
                            <h2 className="box-title" style={{ textAlign: 'left', marginLeft: 15, color: "#ed3237", fontWeight: "unset" }}>Tính năng</h2>

                            <div className="clr" />
                            <div className="tab-content">
                                <div className="tab" style={{marginTop: -15}}>
                                    {
                                        product.features.split('\n').map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {item}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSizeOfCart: () => dispatch(actions.getSizeOfCart())
    }
}

export default connect(null, mapDispatchToProps)(Product);