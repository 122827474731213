import React from 'react';
import loadingGif from '../assets/images/loading.gif'

const Loading = () => {
    return (
        <div id="loading" style={{ width: `${window.innerWidth - 17 > 1200 ? `${window.innerWidth - 17}px` : '1200px'}`, background: "#fff", marginTop: 5}}>
            <img src={loadingGif} alt="" />
        </div>
    );
};

export default Loading;