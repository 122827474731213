import { combineReducers } from 'redux';

import dataOfPage from './dataOfPage';
import sizeCart from './sizeCart';

const reducers = combineReducers({
    dataOfPage,
    sizeCart
});

export default reducers;