import React from 'react';
import img404 from '../assets/images/404.png'

const NotFoundPage = () => {
    return (
        <div className="not-found-page" style={{marginBottom: 50}}>
            <div className="container" />
            <div className="container">
                <div className="fleft" style={{ marginTop: 40, marginLeft: 50, width: '40%' }}>
                    <img
                        src={img404}
                        alt=""
                    />
                </div>
                <div className="404-box fright" style={{ marginTop: 200, marginRight: 40, width: '50%' }}>
                    <p
                        style={{
                            textAlign: "center",
                            fontSize: 45,
                            fontWeight: "bold",
                            color: "#5A5A5A"
                        }}
                    >
                        <span>TRANG KHÔNG TỒN TẠI</span>
                    </p>
                    <p
                        style={{
                            textAlign: "center",
                            lineHeight: 1,
                            color: "#5A5A5A"
                        }}
                    >
                        <span style={{ fontSize: 160, color: "#d72523" }}>404</span>
                        <span style={{ fontWeight: "bold", fontSize: 50 }}>
                            ERROR
                            </span>
                    </p>
                </div>
            </div>
            
            <div className="clr" />
        </div>
    );
};

export default NotFoundPage;