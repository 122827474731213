import React from 'react';
import Item from './Item';

const Category = (props) => {
    let { title, childCategories, id } = props;
    
    return (
        <div className="home-box content-box" id={id}>
            <h2 className="box-title" style={{ textAlign: "justify" }}>
                {title}
            </h2>

            <div className="product-list">
                {
                    childCategories.map((childCategory, index) => {
                        return (
                            <Item
                                key={index}
                                item={childCategory}
                            />
                        )
                    })
                }
            </div>
            <div className="clr"></div>
        </div>
    );
};

export default Category;