const contact = {
    companyName: 'CÔNG TY TNHH PAXAR VIỆT NAM',
    tel: '028.66815196',
    hotline: '0988 330 986',
    guarantee: '0914 270 327',
    fax: '(84-8) 5439 6839',
    email: 'paxalabelvn@gmail.com',
    address: '48/45 Đường TX25, P.Thạnh Xuân, Q.12, TP.HCM',
    website: 'www.paxarvietnam.com'
}

export default contact;