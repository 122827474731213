import React from 'react';
import Category from './Category';

const CategoriesBox = (props) => {
    let {categories} = props;

    return (
        <div className="main-content">
    
            {
                categories.map((category, index) => {
                    return (
                        <Category 
                            key={index}
                            id={category.id}
                            title={category.title}
                            childCategories={category.childCategories}
                        />
                    )
                })
            }
        </div>
    );
};

export default CategoriesBox;