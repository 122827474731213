import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Item = (props) => {
    let { item } = props;

    return (
        <div className="product-item" title= {item.title}>
            <div className="product-img">
                <Link to={item.to}
                    scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
                >
                    {item.price ? <span className="buy-now-label">Mua ngay</span> : null }

                    <img
                        src={item.src}
                        alt=""
                    />
                </Link>
            </div>
            
            <div className="product-info">
                <h3 className="product-title">
                    <Link to={item.to} 
                        scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
                    >
                        {item.title}                    
                    </Link>
                </h3>

                {item.price ? <div className="product-price">{item.price}</div> : null }
            </div>
        </div>
    );
};

export default Item;