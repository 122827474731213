import React, { Component } from 'react';
import './App.css';

import { Route, Switch } from 'react-router-dom'
import cookie from 'react-cookies'

// components
import Footer from './Components/Footer'
import Header from './Components/Header'

// data
import contact from './Data/contact'
import ProductPage from './Pages/ProductPage';
import NotFoundPage from './Pages/NotFoundPage';
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import * as actions from './Actions/index'
import CategoryPage from './Pages/CategoryPage';
import ContactPage from './Pages/ContactPage';
import CartPage from './Pages/CartPage';
import Loading from './Components/Loading';

class App extends Component {
  componentWillUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    if (locationChanged) {
      // Kiểm tra dataOfPage có đúng với route không? sai => getDataOfPage

      switch (window.location.pathname.split('/')[1]) {
        case '':
          if (prevProps.dataOfPage && prevProps.dataOfPage._id !== 'home') {
            this.props.getDataOfPage(window.location.pathname);
          }

          break;

        case 'may-scan':
          if (prevProps.dataOfPage && prevProps.dataOfPage._id !== 'scanners') {
            this.props.getDataOfPage(window.location.pathname);
          }

          break;
        default:
          break;
      }
    }
  }
  componentWillMount() {
    let JSONcart = cookie.load('cart');
    // let JSONcart = JSON.parse(localStorage.getItem("cart"));

    if (!JSONcart) {
      JSONcart = [];
      // localStorage.setItem("cart", JSON.stringify(JSONcart));
      cookie.save('cart', JSONcart, { path: '/' });
    }
  }
  componentDidMount() {
    this.props.getDataOfPage(window.location.pathname);
  }

  render() {
    const paths = ['/', '/may-scan'];
    let { dataOfPage } = this.props;

    switch (window.location.pathname.split('/')[1]) {
      case '':
        if (dataOfPage && dataOfPage._id !== 'home') {
          dataOfPage = null;
        }

        break;

      case 'may-scan':
        if (dataOfPage && dataOfPage._id !== 'scanners') {
          dataOfPage = null;
        }

        break;
      default:
        break;
    }

    console.log(dataOfPage);
    
    return (
      <div className="App" style={{ width: `${window.innerWidth - 17 > 1200 ? `${window.innerWidth - 17}px` : '1200px'}` }}>
        <Header {...{ contact, pathname: window.location.pathname }} />

        {
          !dataOfPage ? <Loading />
            :
            <div style={{ width: 1200, margin: "auto" }}>
              <Switch>
                {
                  paths.map((path, index) => {
                    return (
                      <Route
                        key={index}
                        path={path}
                        exact={true}
                        component={() => <CategoryPage />}
                      />
                    )
                  })
                }

                <Route
                  path='/gio-hang'
                  exact={true}
                  component={() => <CartPage />}
                />

                <Route
                  path='/lien-he'
                  exact={true}
                  component={() => <ContactPage />}
                />

                <Route
                  path='/may-scan/:product'
                  exact={true}
                  component={() => <ProductPage />}
                />

                <Route
                  path='/'
                  component={NotFoundPage}
                />
              </Switch>

            </div>
        }


        <Footer {...{ contact }} />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    dataOfPage: state.dataOfPage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDataOfPage: pathname => dispatch(actions.getDataOfPage(pathname)),
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));