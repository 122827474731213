import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

const ProductPortfolio = (props) => {
    let { productPortfolio } = props;

    return (
        <div className="danh-muc-box">
            <div className="danh-muc-btn">Danh mục sản phẩm</div>
            <div className="danh-muc-menu">
                <ul>
                    {
                        productPortfolio.map((product, index) => {
                            return (
                                <li key={index}>
                                    <Link to={product.href}>
                                        <p style={{ paddingBottom: 5 }}>{product.title}</p>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default ProductPortfolio;