import React from 'react';
import { connect } from 'react-redux'

import Product from '../Components/Product';
import RelatedProducts from '../Components/RelatedProducts';
import NotFoundPage from './NotFoundPage';

const ProductPage = (props) => {
    function shuffle(array) {
        var m = array.length, t, i;

        while (m) {
            i = Math.floor(Math.random() * m--);

            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    }

    let getDataOfProductPage = () => {
        const categories = props.dataOfPage.categories;
        let id = window.location.pathname.split('/')[2];
        let dataOfPage = null;

        let category = categories.find(category => category.id === id.split('-')[0]);
        if (category !== undefined) {
            let childCategories = category.childCategories;
            let pos = childCategories.findIndex(item => item.id === id);

            if (pos !== -1) {
                let product = childCategories[pos];
                let productLeft = [...childCategories.slice(0, pos), ...childCategories.slice(pos + 1, childCategories.length)];
                productLeft = shuffle(productLeft);

                let relatedProducts = [];

                if (productLeft.length > 5) {
                    for (let i = 0; i < 5; i++) {
                        relatedProducts = [...relatedProducts, productLeft[i]];
                    }
                } else {
                    relatedProducts = productLeft;
                }

                dataOfPage = { product, relatedProducts };
            }

        }

        return dataOfPage;
    }

    let getBreadcrumbLink = () => {
        let str = window.location.pathname.split('/');
        let result = ['/'];

        for (let i = 1; i < str.length; i++) {
            let link = '';

            for (let j = 1; j <= i; j++) {
                link = link + '/' + str[j];
            }

            result = [...result, link];
        }

        return result;
    }

    let dataOfProductPage = getDataOfProductPage();

    return (
        <div>
            {
                dataOfProductPage
                    ?
                    <div>
                        <Product product={dataOfProductPage.product} breadcrumbLink={getBreadcrumbLink()} />
                        <RelatedProducts relatedProducts={dataOfProductPage.relatedProducts} />
                    </div>
                    : <NotFoundPage />
            }

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dataOfPage: state.dataOfPage
    }
}

export default connect(mapStateToProps, null)(ProductPage);