import * as types from '../Constants/actionTypes'
import cookie from 'react-cookies'

const getSizeCart = () => {
    // let JSONcart = JSON.parse(localStorage.getItem("cart"));
    let JSONcart = cookie.load('cart');

    let count = 0;

    if (!JSONcart) {
        JSONcart = [];
        // localStorage.setItem("cart", JSON.stringify(JSONcart));
        cookie.save('cart', JSONcart, {path: '/' })
    }

    for (let index in JSONcart) {
        count = count + JSONcart[index].numbers;
    }
    return parseInt(count, 10);
}

let initializeState = getSizeCart();

let reducer = (state = initializeState, action) => {
    switch (action.type) {
        case types.GET_SIZE_OF_CART: {
            return getSizeCart();
        }

        default:
            return state;
    }
}

export default reducer;