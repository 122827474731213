import * as types from '../Constants/actionTypes'
import axios from 'axios'

export const getDataOfPage = pathname => dispatch => {
    pathname = '/' + pathname.split('/')[1];

    let url = "";
    let head = "https://paxavietnam-server.vercel.app/"

    switch (pathname) {
        case '/may-scan':
            url = `${head}api/pages/scanners?key=${process.env.REACT_APP_API_KEY}`;
            break;
        default:
            url = `${head}api/pages/home?key=${process.env.REACT_APP_API_KEY}`;
            break;
    }

    if (url) {
        axios({ method: 'get', url })
            .then(res => {
                dispatch({
                    type: types.GET_DATA_OF_PAGE,
                    dataOfPage: res.data
                })
            })
    } else {
        dispatch({
            type: types.GET_DATA_OF_PAGE,
            dataOfPage: null
        })
    }
}


export const getSizeOfCart = () => {
    return {
        type: types.GET_SIZE_OF_CART,
    }
}