import React from 'react';

const Footer = (props) => {
    let { contact } = props;

    return (
        <footer id="footer">
            <div className="container">
                <div className="footer-row">
                    <div className="footer-widget" style={{ float: "left", marginLeft: 100 }}>
                        <h3 className="widget-title">{contact.companyName}</h3>
                        <div className="widget-sub">
                            <p><span style={{ fontSize: "10pt" }}>{contact.address}</span></p>
                            <p><span style={{ fontSize: "10pt" }}>Hotline: {contact.hotline}</span></p>
                        </div>
                    </div>

                    <div className="footer-widget" style={{ float: "right", marginRight: 100 }}>
                        <h3 className="widget-title">THÔNG TIN LIÊN HỆ</h3>
                        <div className="widget-sub">
                            <p><span style={{ fontSize: "10pt" }}>Email: {contact.email}</span></p>
                            <p><span style={{ fontSize: "10pt" }}>Fax: {contact.fax}</span></p>
                            <p><span style={{ fontSize: "10pt" }}>Website: {contact.website}</span></p>
                        </div>
                    </div>
                    <div className="clr" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;