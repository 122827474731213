import React from 'react';
import ProductPortfolio from '../Components/ProductPortfolio';
import Carousel from '../Components/Carousel';
import CategoriesBox from '../Components/CategoriesBox';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const CategoryPage = (props) => {
    let { dataOfPage } = props;

    return (
        <div>
            <ProductPortfolio productPortfolio={dataOfPage.productPortfolio} />
            <Carousel />

            {
                dataOfPage.breadcrumb.length > 0
                    ? <div className="breadcrumb-box fl">
                        <ul className="breadcrumb">
                            {dataOfPage.breadcrumb.map((item, index) => {
                                return (
                                    <li key={index}><Link to={item.to}>{item.title}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                    : null
            }
            <div className="clr"></div>

            <CategoriesBox categories={dataOfPage.categories} />
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        dataOfPage: state.dataOfPage
    }
}

export default connect(mapStateToProps, null)(CategoryPage);