import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import axios from 'axios'

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fullname: '',
            email: '',
            phone: '',
            content: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    resetState = () => {
        this.setState({
            fullname: '',
            email: '',
            phone: '',
            content: ''
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    async handleSubmit(event) {
        event.preventDefault();
        const { fullname, email, phone, content } = this.state;

        axios({
            method: 'post',
            url: `https://paxavietnam-server.vercel.app/api/message?key=${process.env.REACT_APP_API_KEY}`,
            data: { fullname, email, phone, content }
        })
            .then (() => window.location.search = null)
            .catch(console.log)
        
        this.resetState();
    }

    render() {
        const { fullname, email, phone, content } = this.state;

        return (
            <div>
                <div className="breadcrumb-box fl" style={{ marginTop: 10 }}>
                    <ul className="breadcrumb">
                        <li><Link to="/">TRANG CHỦ</Link></li>
                        <li><Link to="/lien-he">LIÊN HỆ</Link></li>
                    </ul>
                </div>
                <div className="clr"></div>

                <div className="home-box content-box" >
                    <h2 className="box-title" style={{ textAlign: "justify" }}>
                        LIÊN HỆ
                </h2>

                    <div id="map" style={{ float: "left", width: '50%', marginTop: 30, marginBottom: 30 }} >
                        <iframe
                            title="CÔNG TY TNHH PAXAR VIỆT NAM"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.8792053422778!2d106.67787672505497!3d10.882487002559223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d7d4ec5c7b93%3A0x5d7d296110a9944e!2zNDgsIDQ1IMSQLiBUWCAyNSwgVGjhuqFuaCBYdcOibiwgUXXhuq1uIDEyLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZpZXRuYW0!5e0!3m2!1sen!2s!4v1670733928914!5m2!1sen!2s"
                            width={500}
                            height={450}
                            style={{ border: 0 }}
                            allowFullScreen
                        />
                    </div>

                    <div style={{ float: 'right', width: '45%', textAlign: 'left', marginTop: 40, marginLeft: 30 }}>
                        <form style={{ float: 'left' }} onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>
                                    Họ và tên<span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    onChange={this.handleChange}
                                    type="name"
                                    name="fullname"
                                    value={fullname}
                                    className="form-control"
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    Địa chỉ email<span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    onChange={this.handleChange}
                                    type="email"
                                    name="email"
                                    value={email}
                                    className="form-control"
                                    placeholder="Enter email"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    Số điện thoại<span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    onChange={this.handleChange}
                                    type="phone"
                                    name="phone"
                                    value={phone}
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    Nội dung liên hệ<span style={{ color: 'red' }}> *</span>
                                </label>
                                <textarea
                                    onChange={this.handleChange}
                                    name="content"
                                    value={content}
                                    className="form-control"
                                    rows="5"
                                    maxLength="255" required
                                >

                                </textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" style={{ marginTop: 10, width: 515 }}>
                                Gửi yêu cầu
                        </button>
                        </form>
                    </div>

                    <div className="clr"></div>
                </div>
            </div>
        );
    }
}

export default ContactPage;